/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

/***
 * GERAL
 ***/
$(function() {
    var $win = $(window);

    $('header .menuToggle').click(function(event) {
        event.preventDefault();
        $('header nav').slideToggle();
        $('html').toggleClass('open-menu');
    });

    if ($win.width() <= 768) {
        $('header nav ul li a').click(function(event) {
            $('header nav').slideToggle();
            $('html').removeClass('open-menu');
        });
    }

    $win.resize(function(event) {
        $('html').removeClass('open-menu');
        
        if ($win.width() <= 768) {
            $('header nav').css('display', 'none');
        } else {
            $('header nav').css('display', 'block');
        }
    });
    // Scrollspy
    $('body').scrollspy({ 
        target: 'header nav',
    });

    // Scroll animation
    function scrollAnim (target) {
        $(target).animatescroll({
            scrollSpeed:1000,
            easing:'easeOutExpo',
        });
    };

    $('header .scroll').on('click', function(event) {
        scrollAnim($(this).data('target'));
    });

    // Galeria
    $('.galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });

    $('.page-digital .whatsapp').each(function(index, el) {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 150) {
                $('.page-digital .whatsapp').addClass('_fixed');
            } else {
                $('.page-digital .whatsapp').removeClass('_fixed');
            }
        }); 
    }); 

    /*$('#pacotes .col .button').click(function(event) {
        event.preventDefault();
        var pacote = $(this).attr('href');

        $('#pacotes .pacote').addClass('hide');

        $(pacote).toggleClass('hide');
    });*/
});